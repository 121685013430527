import { decrypt } from "../util/decrypt";

const env = decrypt();

export const getConfigByRole = (role) => {
  switch (role) {
    case "general":
      return {
        name: "General",
        config: "FIREBASE_AUTH",
      };
    case "bb-beta":
      return {
        name: "BB Beta",
        config: "FIREBASE_CONFIG_BB_BETA",
      };
    case "justin":
      return {
        name: "BB Justin Nozuka",
        config: "FIREBASE_CONFIG_JUSTIN",
      };
    case "tgs":
      return {
        name: "TGS Union",
        config: "FIREBASE_CONFIG_TGS",
      };
      case "kelley":
        return {
          name: "Brian Kelley",
          config: "FIREBASE_CONFIG_KELLEY",
        };
    case "jjwilde":
      return {
        name: "JJ Wilde",
        config: "FIREBASE_CONFIG_JJ_WILDE",
      };
   case "kelley":
        return {
          name: "Brian Kelley",
          config: "FIREBASE_CONFIG_KELLEY",
       };
   case "wheeler":
      return {
        name: "Wheeler Walker",
        config: "FIREBASE_CONFIG_WHEELER",
      };
    case "bands":
      return {
        name: "Alan Frew",
        config: "FIREBASE_CONFIG_AFREW",
      };
    default:
      return {
        name: "Alan Frew",
        config: "FIREBASE_CONFIG_AFREW",
      };
  }
};

export const getConfig = (config) => {
  if (config) {
    switch (config) {
      case "FIREBASE_CONFIG_AFREW":
        return env.REACT_APP_FIREBASE_CONFIG_AFREW;
      case "FIREBASE_CONFIG_JUSTIN":
        return env.REACT_APP_FIREBASE_CONFIG_JUSTIN;
      case "FIREBASE_CONFIG_TGS":
        return env.REACT_APP_FIREBASE_CONFIG_TGS;
      case "FIREBASE_CONFIG_BB_BETA":
        return env.REACT_APP_FIREBASE_CONFIG_BB_BETA;
      case "FIREBASE_CONFIG_JJ_WILDE":
        return env.REACT_APP_FIREBASE_CONFIG_JJ_WILDE;
     case "FIREBASE_CONFIG_WHEELER":
     return env.REACT_APP_FIREBASE_CONFIG_WHEELER;
     case "FIREBASE_CONFIG_KELLEY":
      return env.REACT_APP_FIREBASE_CONFIG_KELLEY;
      case "FIREBASE_AUTH":
        return env.REACT_APP_FIREBASE_AUTH;
      default:
        return;
    }
  } else {
    return {};
  }
};

export const getGoogleAnalyticsConfig = (config) => {
  switch (config) {
    case "FIREBASE_CONFIG_AFREW":
      return env.REACT_APP_ANALYTICS_AFREW;
    case "FIREBASE_CONFIG_JUSTIN":
      return env.REACT_APP_ANALYTICS_JUSTIN;
    case "FIREBASE_CONFIG_TGS":
      return env.REACT_APP_ANALYTICS_TGS;
    case "FIREBASE_CONFIG_BB_BETA":
      return env.REACT_APP_ANALYTICS_BB_BETA;
    case "FIREBASE_CONFIG_JJ_WILDE":
      return env.REACT_APP_ANALYTICS_JJ_WILDE;
    case "FIREBASE_CONFIG_WHEELER":
      return env.REACT_APP_ANALYTICS_WHEELER;
    case "FIREBASE_CONFIG_KELLEY":
      return env.REACT_APP_ANALYTICS_KELLEY;
    default:
      return;
  }
};

export const getGooglePlayConfig = (config) => {
  switch (config) {
    case "FIREBASE_CONFIG_AFREW":
      return "";
    case "FIREBASE_CONFIG_JUSTIN":
      return "justinnozuka";
    case "FIREBASE_CONFIG_TGS":
      return "theglorioussons";
    case "FIREBASE_CONFIG_BB_BETA":
      return "";
    case "FIREBASE_CONFIG_JJ_WILDE":
      return "";
    case "FIREBASE_CONFIG_KELLEY":
      return "";
    case "FIREBASE_CONFIG_WHEELER":
       return "";
    default:
      return;
  }
};

export const getConfigByName = (name) => {
  switch (name) {
    case "general":
      return {
        name: "General",
        config: "FIREBASE_AUTH",
      };
    case "beta":
      return {
        name: "BB Beta",
        config: "FIREBASE_CONFIG_BB_BETA",
      };
    case "justin":
      return {
        name: "BB Justin Nozuka",
        config: "FIREBASE_CONFIG_JUSTIN",
      };
    case "tgs":
      return {
        name: "TGS Union",
        config: "FIREBASE_CONFIG_TGS",
      };
    case "jjwilde":
      return {
        name: "JJ Wilde",
        config: "FIREBASE_CONFIG_JJ_WILDE",
      };
      case "kelley":
        return {
          name: "Brian Kelley",
          config: "FIREBASE_CONFIG_KELLEY",
        };
    case "wheeler":
        return {
          name: "Wheeler Walker",
          config: "FIREBASE_CONFIG_WHEELER",
        };
    default:
      return {
        name: "Alan Frew",
        config: "FIREBASE_CONFIG_AFREW",
      };
  }
};

export const getConfigByPath = () => {
  const actualPath = window.location.href;
  actualPath.substr(window.location.origin.length);
  const pathArray = actualPath.substr(window.location.origin.length).split('/');
  const projectName = pathArray[1];
  return getConfigByName(projectName);
};

