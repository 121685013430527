export const getValuesFromDeeplyNestedProperty = (obj, propertyAccessorString) => {
  const accesorArray = propertyAccessorString.split('[x].')

  let intermediateArray = getSimpleNestedPoperty(obj, accesorArray[0]);

  for (let i = 1; i < accesorArray.length; i++) {
    if (!intermediateArray) {
      return intermediateArray
    }

    const accumulator = [];

    for (let j = 0; j < intermediateArray.length; j++) {
      accumulator.push(getSimpleNestedPoperty(intermediateArray[j], accesorArray[i]));

    }

    intermediateArray = accumulator.flat();
  }

  return intermediateArray;
}

export const getSimpleNestedPoperty = (obj, propertyAccessorString) => {
  if (!propertyAccessorString || !obj) {
    return obj;
  }

  const accesorArray = propertyAccessorString.split('.');
  let intermediateObject = obj[accesorArray[0]];

  for (let i=1; i<accesorArray.length; i++) {
    intermediateObject = intermediateObject[accesorArray[i]]
  }

  return intermediateObject;

}

export const searchInObject = (arr, term, config) => {
  term = term.toLowerCase();

  return arr.filter((item) => {
    for (let j = 0; j < config.length; j++) {
      let intermediateArray = getValuesFromDeeplyNestedProperty(item, config[j]);

      if (!intermediateArray) {
        continue;
      }

      if (!Array.isArray(intermediateArray)) {
        if (typeof intermediateArray === 'string') {
          if (intermediateArray.toLowerCase().includes(term)) {
            return true;
          } else {
            continue;
          }
        }
        if (typeof intermediateArray === 'number') {
          if (intermediateArray.toString().includes(term)) {
            return true;
          } else {
            continue;
          }
        }
        continue;
      }

      if (intermediateArray.some((result) => {
        if (typeof result === 'string') {
          return result.toLowerCase().includes(term)
        }
        return false;
      })) {
        return true;
      }
    }

    return false;
  })
}