import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

const UserInfo = () => {

  const dispatch = useDispatch();
  const { config } = useSelector(({ configs }) => configs);

  const getPopoverColor = () => {
    switch (config.name) {
      case "BB Justin Nozuka":
        return "justin-nozuka-pop";
      case "TGS Union":
        return "tg-union-pop";
      default:
        return "";
    }
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <Popover overlayClassName={getPopoverColor()} placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar src={"https://via.placeholder.com/150"} className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  );
};

export default UserInfo;
