export const projectData = [
  {
    name: "Alan Frew",
    config: "FIREBASE_CONFIG_AFREW",
  },
  {
    name: "BB Justin Nozuka",
    config: "FIREBASE_CONFIG_JUSTIN",
  },
  {
    name: "TGS Union",
    config: "FIREBASE_CONFIG_TGS",
  },
  {
    name: "BB Beta",
    config: "FIREBASE_CONFIG_BB_BETA",
  },
  {
    name: "JJ Wilde",
    config: "FIREBASE_CONFIG_JJ_WILDE",
  },
  {
    name: "Wheeler Walker",
    config: "FIREBASE_CONFIG_WHEELER",
  },
  {
    name: "Brian Kelley",
    config: "FIREBASE_CONFIG_KELLEY",
  },
  {
    name: "General",
    config: "FIREBASE_AUTH",
  },
];

export const projectBands = [
  {
    name: "Alan Frew",
    config: "FIREBASE_CONFIG_AFREW",
  },
  {
    name: "BB Justin Nozuka",
    config: "FIREBASE_CONFIG_JUSTIN",
  },
  {
    name: "TGS Union",
    config: "FIREBASE_CONFIG_TGS",
  },
  {
    name: "JJ Wilde",
    config: "FIREBASE_CONFIG_JJ_WILDE",
  },
  {
    name: "Brian Kelley",
    config: "FIREBASE_CONFIG_KELLEY",
  },
  {
    name: "Wheeler Walker",
    config: "FIREBASE_CONFIG_WHEELER",
  }
];
