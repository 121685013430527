import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import { getConfigByRole } from "./utilsFirebase";
import { message } from "antd";
import { decrypt } from "../util/decrypt";
import { resizeFile } from "../util/imageSize";
import { getCustomToken } from "../apiFunctions/blackboxApi";
import { v4 as uuidv4 } from "uuid";

const env = decrypt();

export class FirebaseApp {
  config = "";

  constructor(config) {
    this.config = config;
  }

  /**
   * TODO: optimize - check for same config not to create the same app again
   */
  async createApp() {
    if (this.config === null) {
      const userRole = localStorage.getItem("user_role");
      if (userRole) {
        this.config = getConfigByRole(userRole);
        console.log(userRole);
      }
    }

    let name, config;
    if (this.config) {
      name = this.config.name;
      config = this.config.config;
    }
    const firebaseApps = firebase.apps.filter((app) => app.name === name);
    const newConfig = this.getConfig(config);

    if (firebaseApps.length === 0) {
      // create new app if it doesn't exist
      try {
        await firebase.initializeApp(newConfig, name);
      } catch (err) {
        console.log(err);
      }
    }
  }

  getFirestore() {
    try {
      if (this.config) {
        this.createApp();
        const app = firebase.firestore(firebase.app(this.config.name));

        return app;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async loginForApp() {
    try {
      await this.createApp();
      await firebase
        .auth(firebase.app(this.config.name))
        // TODO: change credentials or find a better way
        .signInWithEmailAndPassword("bogdan@wearewip.com", "Admin123!?");
    } catch (err) {
      console.log(err);
    }
  }

  async loginWithCustomToken(token) {
    try {
      await this.createApp();
      await firebase
        .auth(firebase.app(this.config.name))
        .signInWithCustomToken(token)
        .then((userCredential) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  }

  getFirestorage() {
    try {
      if (this.config) {
        this.createApp();
        const app = firebase.storage(firebase.app(this.config.name));

        return app;
      }
    } catch (err) {
      console.log(err);
    }
  }

  getConfig(config) {
    if (config) {
      switch (config) {
        case "FIREBASE_CONFIG_AFREW":
          return env.REACT_APP_FIREBASE_CONFIG_AFREW;
        case "FIREBASE_CONFIG_JUSTIN":
          return env.REACT_APP_FIREBASE_CONFIG_JUSTIN;
        case "FIREBASE_CONFIG_TGS":
          return env.REACT_APP_FIREBASE_CONFIG_TGS;
        case "FIREBASE_CONFIG_BB_BETA":
          return env.REACT_APP_FIREBASE_CONFIG_BB_BETA;
        case "FIREBASE_CONFIG_JJ_WILDE":
          return env.REACT_APP_FIREBASE_CONFIG_JJ_WILDE;
        case "FIREBASE_CONFIG_WHEELER":
          return env.REACT_APP_FIREBASE_CONFIG_WHEELER;
        case "FIREBASE_CONFIG_KELLEY":
          return env.REACT_APP_FIREBASE_CONFIG_KELLEY;
        case "FIREBASE_AUTH":
          return env.REACT_APP_FIREBASE_AUTH;
        default:
          return;
      }
    } else {
      return {};
    }
  }

  async checkLoggedIn() {
    this.createApp();
    const curUser = firebase.auth(firebase.app(this.config.name)).currentUser;
    if (!curUser) {
      await this.loginForApp();
    }
  }
}

export const getAll = async (firebaseConfig, resource, setMethod) => {
  if (firebaseConfig !== "" || firebaseConfig !== null) {
    const fs = new FirebaseApp(firebaseConfig);

    try {
      if (firebaseConfig.name !== "General") {
        const response = await getCustomToken(firebaseConfig);
        if (response && response.customToken) {
          await fs.loginWithCustomToken(response.customToken);
        }
      }
    } catch (err) {
      console.log(err);
    }

    const firestore = await fs.getFirestore();
    return new Promise((resolve, reject) => {
      try {
        if (firestore) {
          const dumpArray = [];
          firestore
            .collection(resource)
            .get()
            .then((snap) => {
              snap.forEach((item) => {
                dumpArray.push({ uid: item.id, ...item.data() });
              });
              setMethod(dumpArray);
              resolve(dumpArray);
            });
        }
      } catch (e) {
        reject(e);
      }
    });
  }
};

export const getOneById = async (firebaseConfig, resource, setMethod, id) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.loginForApp();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const firestore = await fs.getFirestore();
    if (firestore) {
      firestore
        .collection(resource)
        .doc(id)
        .get()
        .then((item) => setMethod(item.data()))
        .catch((err) => console.log(err));
    }
  }
};

export const deleteById = async (firebaseConfig, resource, id) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.loginForApp();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const firestore = await fs.getFirestore();
    if (firestore) {
      firestore
        .collection(resource)
        .doc(id)
        .delete()
        .then(() => message.success(`Item with id ${id} deleted...`))
        .catch((err) => message.error(err));
    }
  }
};

export const updateById = async (firebaseConfig, resource, id, item) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.loginForApp();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const firestore = await fs.getFirestore();
    if (firestore) {
      firestore
        .collection(resource)
        .doc(id)
        .update({ ...item })
        .then(() => {
          message.success(`Item with id ${id} updated`);
        })
        .catch((err) => message.error(err));
    }
  }
};

export const imageUrl = async (firebaseConfig, fileName) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.checkLoggedIn();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const storage = await fs.getFirestorage();

    if (storage) {
      const img = storage.ref(fileName);

      if (img) {
        return await img.getDownloadURL();
      }
    }

    return null;
  }
};

export const getAllFilesByPath = async (
  firebaseConfig,
  path,
  setMethod
) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.checkLoggedIn();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const storage = await fs.getFirestorage();

    if (storage) {
      const folderRef = storage.ref(`/${path}`);
      await folderRef
        .listAll()
        .then( async (result) => {
          let idx = 1;
          const items = [];
          for (const itemRef of result.items) { 
            const url = await itemRef.getDownloadURL();
            //extract the file name from the URL in the title property
            items.push({uid:idx, title: decodeURIComponent(new URL(url).pathname).split('transcoded/')[1].split('.m4a')[0], url:url});
            idx++;
          }
          setMethod(items);
        })
        .catch((error) => console.log("error", error));
    }
  }
};

export const deleteFilesByURL = async (
  firebaseConfig,
  items,
) => {
  const fs = new FirebaseApp(firebaseConfig);

  if (firebaseConfig.name !== "General") {
    const { customToken } = await getCustomToken(firebaseConfig);
    await fs.loginWithCustomToken(customToken);
  }

  const storage = await fs.getFirestorage();

  if(storage) {
    for(const item of items) {
      const itemRef = storage.refFromURL(item.url);

      if(itemRef) {
        await itemRef.delete();
      }
    }  
  }
};

export const uploadImage = async (
  firebaseConfig,
  resource,
  id,
  image,
  path,
  pathField
) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.checkLoggedIn();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const imageType = image.type.slice(image.type.indexOf("/") + 1);
    const resizedImage = await resizeFile(image, imageType);
    const storage = await fs.getFirestorage();

    if (storage) {
      const imageRef = storage.ref(`/${path}`);
      await imageRef
        .put(resizedImage)
        .then(() => updateById(firebaseConfig, resource, id, pathField))
        .catch((error) => console.log("error", error));
    }
  }
};

export const uploadTranscodedAudio = async (
  firebaseConfig,
  file,
  path
) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.checkLoggedIn();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const storage = await fs.getFirestorage();

    if (storage) {
      const fileRef = storage.ref(`/${path}`);
      await fileRef
        .put(file)
        .then((data) => console.log(data))
        .catch((error) => console.log("error", error));

      return await fileRef.getDownloadURL();
    }
  }
};

/**
 *
 * @param firebaseConfig
 * @param resource
 * @param {string|string[]} paths
 * @returns {Promise<*>}
 */
export const deleteImageFromFirebaseStorage = async (
  firebaseConfig,
  resource,
  paths
) => {
  if (!firebaseConfig) {
    return;
  }

  const fs = new FirebaseApp(firebaseConfig);

  if (firebaseConfig.name !== "General") {
    const { customToken } = await getCustomToken(firebaseConfig);
    await fs.loginWithCustomToken(customToken);
  }

  const storage = await fs.getFirestorage();

  if (!storage) {
    return;
  }

  if (Array.isArray(paths)) {
    const deletionPromises = [];

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      deletionPromises.push(storage.ref(`/${path}`).delete());
    }

    return Promise.all(deletionPromises);
  } else {
    return storage.ref(`/${paths}`).delete();
  }
};

export const addWithId = async (firebaseConfig, resource, item) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.loginForApp();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    const firestore = await fs.getFirestore();
    if (firestore) {
      if (resource === "logs") {
        firestore
          .collection(resource)
          .add({ ...item })
          .then((docRef) => {
            firestore
              .collection(resource)
              .doc(docRef.id)
              .update({ contentId: docRef.id });
            message.success(`Item with id ${docRef.id} created`);
          })
          .catch((err) => message.error(err));
      } else {
        firestore
          .collection(resource)
          .add({ ...item })
          .then((docRef) => {
            firestore
              .collection(resource)
              .doc(docRef.id)
              .update({ id: docRef.id });
            message.success(`Item with id ${docRef.id} created`);
          })
          .catch((err) => message.error(err));
      }
    }
  }
};

export const addAndReturnId = async (firebaseConfig, resource, item) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    // await fs.loginForApp();
    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }

    let id = "";
    const firestore = await fs.getFirestore();
    if (firestore) {
      await firestore
        .collection(resource)
        .add({ ...item })
        .then((docRef) => {
          id = docRef.id;
          message.success(`Item with id ${docRef.id} created`);
          //setLoadingMethod(false);
        })
        .catch((err) => message.error(err));
    }
    return id;
  }
};

export const getCurrentVersion = async (firebaseConfig, resource) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);
    await fs.loginForApp();
    const firestore = await fs.getFirestore();
    return firestore
      .collection(resource)
      .where("currentVersion", "==", true)
      .get();
  }
};

export const getAllNotificationsByStatus = async (
  firebaseConfig,
  resource,
  setMethod
) => {
  if (firebaseConfig !== "" || firebaseConfig !== null) {
    const fs = new FirebaseApp(firebaseConfig);

    try {
      if (firebaseConfig.name !== "General") {
        const response = await getCustomToken(firebaseConfig);
        if (response && response.customToken) {
          await fs.loginWithCustomToken(response.customToken);
        }
      }
    } catch (err) {
      console.log(err);
    }

    const firestore = await fs.getFirestore();

    return new Promise((resolve, reject) => {
      try {
        if (firestore) {
          const dumpArray = [];
          firestore
            .collection(resource)
            .where("status", "==", false)
            .get()
            .then((snap) => {
              snap.forEach((item) => {
                dumpArray.push({ uid: item.id, ...item.data() });
              });
              setMethod(dumpArray);
              resolve(dumpArray);
            });
        }
      } catch (e) {
        reject(e);
      }
    });
  }
};

export const createCurrentSubscriptionPlan = async (
  firebaseConfig,
  subscriptionData
) => {
  if (firebaseConfig !== "" || firebaseConfig !== null) {
    const fs = new FirebaseApp(firebaseConfig);

    try {
      if (firebaseConfig.name !== "General") {
        const response = await getCustomToken(firebaseConfig);
        if (response && response.customToken) {
          await fs.loginWithCustomToken(response.customToken);
        }
      }
    } catch (err) {
      console.log(err);
    }

    const firestore = await fs.getFirestore();
    if (firestore) {
      firestore.collection("plans").add(subscriptionData);
    }
  }
};

export const getUserCount = (firebaseConfig) => {
  return new Promise(async (resolve) => {
    if (firebaseConfig !== "" || firebaseConfig !== null) {
      const fs = new FirebaseApp(firebaseConfig);

      try {
        if (firebaseConfig.name !== "General") {
          const response = await getCustomToken(firebaseConfig);
          if (response && response.customToken) {
            await fs.loginWithCustomToken(response.customToken);
          }
        }
      } catch (err) {
        console.log(err);
      }
      const firestore = await fs.getFirestore();
      if (firestore) {
        firestore
          .collection("profiles")
          .get()
          .then((users) => {
            resolve(users.size);
          });
      }
    }
  });
};

export const saveSubscriptionToFirebase = async (
  firebaseConfig,
  subscriptionData
) => {
  if (firebaseConfig !== "" || firebaseConfig !== null) {
    const fs = new FirebaseApp(firebaseConfig);

    try {
      if (firebaseConfig.name !== "General") {
        const response = await getCustomToken(firebaseConfig);
        if (response && response.customToken) {
          await fs.loginWithCustomToken(response.customToken);
        }
      }
    } catch (err) {
      console.log(err);
    }

    const firestore = await fs.getFirestore();
    if (firestore) {
      firestore.collection("subscriptions").add(subscriptionData);
    }
  }
};

/**
 * Generate qr codes for merch base on the item id
 * @param {string} id
 */
export const generateQrCodes = async (id, firebaseConfig) => {
  if (firebaseConfig !== "" || firebaseConfig !== null) {
    const fs = new FirebaseApp(firebaseConfig);
    const firestore = await fs.getFirestore();

    // Generate qr codes - currently set to 2000
    const batchArray = [];
    batchArray.push(firestore.batch());
    let operationCount = 0;
    let batchIndex = 0;

    for (let index = 0; index < 2000; index++) {
      const uniqueString = uuidv4().replace(/-/g, "");

      const item = await firestore
        ?.collection("merch")
        .doc(id)
        .collection("qrcodes")
        .doc(uniqueString);

      if (item) {
        batchArray[batchIndex]?.set(item, {
          code: `${id}-${uniqueString}`,
          validated: false,
        });
        operationCount++;

        if (operationCount === 499) {
          batchArray.push(firestore.batch());
          batchIndex++;
          operationCount = 0;
        }
      }
    }

    batchArray.forEach(async (batch) => await batch?.commit());
  }
};

export const getQrCodes = async (id, firebaseConfig) => {
  const dataToSend = [];

  if (firebaseConfig !== "" || firebaseConfig !== null) {
    const fs = new FirebaseApp(firebaseConfig);
    const firestore = await fs.getFirestore();
    const items = await firestore
      ?.collection("merch")
      .doc(id)
      .collection("qrcodes")
      .get();

    items?.docs.forEach((item) => {
      dataToSend.push(item.data());
    });
  }

  return dataToSend;
};

export const deleteAll = async (firebaseConfig, resource, idToBeDeleted) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }
    const firestore = await fs.getFirestore();
    const batch = firestore.batch();
    if (batch) {
      for (const item of idToBeDeleted) {
        const ref = firestore.collection(resource).doc(item.uid);
        if (ref) batch.delete(ref);
      }
      batch.commit().catch((error) => {
        console.log("DeleteMany error", { error });
      });
    }
  }
};

export const deleteFieldFromFirebase = async (
  firebaseConfig,
  resource,
  id,
  fieldToBeDeleted
) => {
  if (firebaseConfig) {
    const fs = new FirebaseApp(firebaseConfig);

    if (firebaseConfig.name !== "General") {
      const { customToken } = await getCustomToken(firebaseConfig);
      await fs.loginWithCustomToken(customToken);
    }
    const firestore = await fs.getFirestore();
    if (firestore) {
      await firestore
        .collection(resource)
        .doc(id)
        .update({
          [fieldToBeDeleted]: firebase.firestore.FieldValue.delete(),
        });
    }
  }
};
