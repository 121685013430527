import React, { useEffect, useState } from "react";
import {Button, Form, message, Space, Upload} from "antd";
import { getAll, imageUrl, uploadImage } from "../../firebase/apiFirebase";
import { getConfigByPath } from "../../firebase/utilsFirebase";
import { Link } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import { getImageInfo } from "../../util/imageSize";
import { UploadOutlined } from "@ant-design/icons";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getAppName } from "../../apiFunctions/blackboxApi";
import { deleteSelectedEntries } from "../../util/deleteFunctions";

const ConfigsPage = ({setPhoto, logos}) => {
  const config = getConfigByPath();
  const app = getAppName(config);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPhoto, setNewPhoto] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const first = a.name?.toLowerCase();
        const second = b.name?.toLowerCase();
        if(first < second)
          return - 1;
        else if (first > second)
          return 1;
        return 0;
      }
    },
    {
      title: "Main Color",
      dataIndex: "color1",
      key: "color1",
      render: (color) => (
        <div className="square" style={{backgroundColor: color}} />
        ),
    },
    {
      title: "Highlight color",
      dataIndex: "color2",
      key: "color3",
      render: (color) => (
        <div className="square" style={{backgroundColor: color}} />
      ),
    },
    {
      title: "Dark Color",
      dataIndex: "color3",
      key: "color3",
      render: (color) => (
        <div className="square" style={{backgroundColor: color}} />
      ),
    },
    {
      title: "Light color",
      dataIndex: "color4",
      key: "color4",
      render: (color) => (
        <div className="square" style={{backgroundColor: color}} />
      ),
    },
    {
      title: "Extra color",
      dataIndex: "color5",
      key: "color5",
      render: (color) => (
        <div className="square" style={{backgroundColor: color}} />
      ),
    },
    {
      title: "Current version",
      dataIndex: "currentVersion",
      key: "currentVersion",
      render: currentVersion => currentVersion ? <CheckOutlined /> : <CloseOutlined />,
    },
    {
      key: "actions",
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/${app}/configs/${record.uid}`}>View</Link>
          <Link to={`/${app}/configs/${record.uid}/edit`}>Edit</Link>
        </Space>
      ),
    },
  ];

  const propsPhoto = {
    name: 'image',
    accept: 'image/*',
    multiple: false,
    beforeUpload: Upload.LIST_IGNORE,
    action: "#",
    onChange(info) {
      setNewPhoto(info.file);
      acceptTypeFilePhoto(info.file);
    },
    onRemove() {
      setNewPhoto("");
    }
  };

  const acceptTypeFilePhoto = (image) => {
    const isLt2M = image.size / 512 / 512 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 512X512 resolution!').then();
    }
    return isLt2M;
  }

  useEffect(() => {
    getAll(config, "configs", setData).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },  []);

  const handleSubmit = async(values) => {
    const id = logos && logos[0] && logos[0].uid;
    if (newPhoto) {
      const dimensions = await getImageInfo(newPhoto);
      values.height = dimensions.height;
      values.width = dimensions.width;
    }
    if (id) {
      if (newPhoto) {
        const path = `logos/${id}/photo`;
        const pathField = { logo: path };
        await uploadImage(config, "logos", id, newPhoto, path, pathField);
        imageUrl(config, path).then((res) => (
          setPhoto(res)
        ));
      }
    }
  };

  return (
    <div>
      <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
        <h2 className="title gx-mb-4">Themes</h2>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="create-logo"
        onFinish={(values) => handleSubmit(values)}
      >
        <div className="config-title">Choose your logo for CMS</div>
        <Form.Item label="Logo">
          <Space direction="vertical" size="large">
            <Upload
              className="upload"
              {...propsPhoto}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </Space>
        </Form.Item>
        <div className="gx-d-flex gx-justify-content-between">
          <Button type="primary" htmlType="submit">
            Save Logo
          </Button>
        </div>
      </Form>
      <h2 className="config-title">Choose&#44; add or create your version</h2>
        <div>
            <CustomTable
              data={data}
              columns={columns}
              hasExportButton
              createLink={`/${app}/configs/create`}
              field="name"
              rowKey="uid"
              type="single"
              resource="configs"
              loading={loading}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              deleteAll={() => deleteSelectedEntries(config, "configs", data, setData, selectedRows, setSelectedRows)}
            />
        </div>
    </div>
  );
};

export default ConfigsPage;
