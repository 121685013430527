import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';

// Initialize Firebase
// const config = process.env.REACT_APP_FIREBASE_AUTH || {};
/**
 * TODO: check if we can load the config from .env
 */
const config = {
  apiKey: "AIzaSyDYt78ijZ9TdU44nnxbtuMUCsmzJ3ypQds",
  authDomain: "blackbox-authprovider.firebaseapp.com",
  projectId: "blackbox-authprovider",
  storageBucket: "blackbox-authprovider.appspot.com",
  messagingSenderId: "255782185819",
  appId: "1:255782185819:web:66e89c25f209ccaba5087e",
};

firebase.initializeApp(config);
const auth = firebase.auth();

const fsDb = firebase.firestore();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();

export {
  database,
  auth,
  fsDb,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
};
