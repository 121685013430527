export const getButtonsColor = (config) => {
  let className = "";
  if (config) {
    switch (config.name) {
      case "BB Justin Nozuka":
        className = "justin-button-edit";
        break;
      case "TGS Union":
        className = "union-button-edit";
        break;
      case "Alan Frew":
        className = "alan-button-edit";
        break;
      case "JJ Wilde":
        className = "jjwilde-button-edit";
        break;
      case "Brian Kelley":
        className = "kelley-button-edit";
        break;
      case "Wheeler Walker":
        className = "wheeler-button-edit";
        break;
      default:
        className = "";
        break;
    }
  }
  return className;
};
