import React, {useEffect, useState} from "react";
import { Layout } from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText, currentYear } from "util/config";
import App from "routes/index";
import { useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { getConfigByPath } from "../../firebase/utilsFirebase";

const { Content, Footer } = Layout;

const MainApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const [photo, setPhoto] = useState("");
  const [logos, setLogos] = useState();

  const config = getConfigByPath();

  const applyDarkTheme = () => {
    document.body.classList.add('dark-theme');
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/dark_theme.css";
    link.className = 'style_dark_theme';
    document.body.appendChild(link);
  }

  useEffect(() => {
    if  (config && config.name) {
      if (config.name === "BB Justin Nozuka") {
        document.body.classList.add('justin');
        document.body.classList.remove('union');
        document.body.classList.remove('alan');
        document.body.classList.remove('jjwilde');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('kelley');


      } else if (config.name === "TGS Union") {
        document.body.classList.add('union');
        document.body.classList.remove('justin');
        document.body.classList.remove('alan');
        document.body.classList.remove('jjwilde');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('kelley');


      } else if (config.name === "General") {
        document.body.classList.remove('justin');
        document.body.classList.remove('union');
        document.body.classList.remove('alan');
        document.body.classList.remove('jjwilde');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('kelley');


      } else if (config.name === "BB Beta") {
        document.body.classList.remove('justin');
        document.body.classList.remove('union');
        document.body.classList.remove('alan');
        document.body.classList.remove('jjwilde');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('kelley');


      } else if (config.name === "Alan Frew") {
        document.body.classList.add('alan');
        document.body.classList.remove('justin');
        document.body.classList.remove('union');
        document.body.classList.remove('jjwilde');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('kelley');

      } else if (config.name === "JJ Wilde") {
        document.body.classList.add('jjwilde');
        document.body.classList.remove('justin');
        document.body.classList.remove('union');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('alan');
        document.body.classList.remove('kelley');

      } else if (config.name === "Wheeler Walker") {
        document.body.classList.add('wheeler');
        document.body.classList.remove('justin');
        document.body.classList.remove('jjwilde');
        document.body.classList.remove('union');
        document.body.classList.remove('alan');
        document.body.classList.remove('kelley');

      } else if (config.name === "Brian Kelley") {
        document.body.classList.add('kelley');
        document.body.classList.remove('justin');
        document.body.classList.remove('union');
        document.body.classList.remove('wheeler');
        document.body.classList.remove('alan');
        document.body.classList.remove('jjwilde');

      }
    }
    // eslint-disable-next-line
  }, [config.name]);

  applyDarkTheme();

  const getDatabaseColor = () => {
    switch (config && config.name) {
      case "General":
        return "custom-style-blue";
      case "BB Justin Nozuka":
        return "custom-style-pink";
      case "TGS Union":
        return "custom-style-orange";
      case "Alan Frew":
        return "custom-style-green";
      case "JJ Wilde":
        return "custom-style-jj-pink";
      case "Brian Kelley":
        return "custom-style-blue";
      case "Wheeler Walker":
          return "custom-style-sand";
      default:
        return "";
    }
  };

  const getDatabaseBackground = () => {
    switch (config && config.name) {
      case "BB Justin Nozuka":
        return "justin-nozuka-background";
      case "TGS Union":
        return "tg-union-background";
      case "JJ Wilde":
        return "jjwilde-background";
      case "Brian Kelley":
        return "kelley-background";
      case "Wheeler Walker":
          return "wheeler-background";
      default:
        return "";
    }
  };

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar photo={photo} setPhoto={setPhoto} logos={logos} setLogos={setLogos} />;
      case NAV_STYLE_DRAWER:
        return <Topbar photo={photo} setPhoto={setPhoto} logos={logos} setLogos={setLogos} />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar photo={photo} setPhoto={setPhoto} logos={logos} setLogos={setLogos} />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  return (
    <Layout className={`gx-app-layout ${getDatabaseBackground()}`}>
      <Sidebar className={getDatabaseColor()} />
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)}`}>
          <App match={match} setPhoto={setPhoto} logos={logos} />
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}{currentYear}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

