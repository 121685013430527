import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem("user_name");
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  const { config } = useSelector(({ configs }) => configs);

  const getPopoverColor = () => {
    switch (config && config.name) {
      case "BB Justin Nozuka":
        return "blue";
      case "TGS Union":
        return "orange";
      default:
        return "";
    }
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row gx-mt-1">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        color={getPopoverColor()}
      >
        <Avatar
          src="https://via.placeholder.com/150"
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="">
          {userName}
          <i className="icon icon-chevron-down gx-pl-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
