import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth, fsDb } from "../../firebase/firebase";
import { SIGNIN_USER, SIGNOUT_USER, SIGNUP_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";
import { removeProjectConfig } from "../actions/Configs";
import { getConfigByRole } from "../../firebase/utilsFirebase";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password, Username } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );

    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      console.log("else");
      localStorage.setItem("user_id", signUpUser.user.uid);

      // Add the user in users collection
      console.log("Adding user to collection");
      fsDb
        .collection("users")
        .doc(signUpUser.user.uid)
        .set({ email, name: Username, role: "admin" });

      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );

    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.user.uid);
      yield call(getUserProfile, signInUser.user.uid);
      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const getUserProfile = async (userID) => {
  const docRef = await fsDb.collection("users").doc(userID);
  return docRef
    .get()
    .then((doc) => {
      const userName = doc.data().name || doc.data().email;
      localStorage.setItem("user_name", userName);
      localStorage.setItem("user_email", doc.data().email);
      localStorage.setItem("user_role", doc.data().role);
      if (doc.data().role !== "admin" || doc.data().role !== "bands") {
        localStorage.setItem("usrcf", JSON.stringify(getConfigByRole(doc.data().role)));
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
};

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("usrcf");
      localStorage.removeItem("user_role");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_email");
      yield put(removeProjectConfig());
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(createUserAccount), fork(signOutUser)]);
}
