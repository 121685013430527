import { SET_PROJECT_CONFIG, REMOVE_PROJECT_CONFIG } from "constants/ActionTypes";

export const setProjectConfig = (config) => {
  return {
    type: SET_PROJECT_CONFIG,
    payload: config,
  };
};

export const removeProjectConfig = () => {
  return {
    type: REMOVE_PROJECT_CONFIG,
  };
};

