import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import ConfigsPage from "./ConfigsPage";
import { getConfigByPath } from "../firebase/utilsFirebase";

const App = ({match, setPhoto, logos}) => {
  const config = getConfigByPath();

  const isGeneral = config && config.name === "General";

  return (
    <div className="gx-main-content-wrapper">
        <Switch>

          <Route
            exact
            path={`${match.url}:app/logs/create`}
            component={asyncComponent(() => import("./LogsPage/CreateLogsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/albums/create`}
            component={asyncComponent(() => import("./AlbumsPage/CreateAlbumPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/merch/create`}
            component={asyncComponent(() => import("./MerchPage/CreateMerchPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/subscriptions`}
            component={asyncComponent(() => import("./SubscriptionsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/logs/:id/edit`}
            component={asyncComponent(() =>
              import("./LogsPage/EditLogsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/logs/:id`}
            component={asyncComponent(() =>
              import("./LogsPage/ViewLogsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/albums/:id/edit`}
            component={asyncComponent(() =>
              import("./AlbumsPage/EditAlbumsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/albums/:id`}
            component={asyncComponent(() =>
              import("./AlbumsPage/ViewAlbumsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/merch/:id/edit`}
            component={asyncComponent(() =>
              import("./MerchPage/EditMerchPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/merch/:id`}
            component={asyncComponent(() =>
              import("./MerchPage/ViewMerchPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/configs/create`}
            component={asyncComponent(() =>
              import("./ConfigsPage/CreateConfigsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/configs/:id/edit`}
            component={asyncComponent(() =>
              import("./ConfigsPage/EditConfigsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/configs/:id`}
             component={asyncComponent(() =>
               import("./ConfigsPage/ViewConfigsPage")
             )}
          />

        {!isGeneral &&
          <Route exact path={`${match.url}:app/configs`}>
            <ConfigsPage setPhoto={setPhoto} logos={logos} />
          </Route>
        }

          <Route
            exact
            path={`${match.url}:app/admin/create`}
            component={asyncComponent(() =>
              import("./AdminPage/CreateAdminPage")
            )}
          />

          {isGeneral &&
              <Route
                  exact
                  path={`${match.url}:app/admin`}
                  component={asyncComponent(() => import("./AdminPage"))}
              />
          }

          <Route
            exact
            path={`${match.url}:app/admin/:id`}
            component={asyncComponent(() =>
              import("./AdminPage/ViewAdminPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/admin/:id/edit`}
            component={asyncComponent(() =>
              import("./AdminPage/EditAdminPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/overview`}
            component={asyncComponent(() => import("./OverviewPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/upload-video`}
            component={asyncComponent(() => import("./UploadVideo"))}
          />

          <Route
            exact
            path={`${match.url}:app/notifications`}
            component={asyncComponent(() => import("./NotificationsPage/index"))}
          />

          <Route
              exact
              path={`${match.url}:app/profiles`}
              component={asyncComponent(() => import("./ProfilesPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/profiles/:id`}
            component={asyncComponent(() =>
              import("./ProfilesPage/ViewProfilePage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/profiles/:id/edit`}
            component={asyncComponent(() =>
              import("./ProfilesPage/EditProfilePage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/live-events`}
            component={asyncComponent(() => import("./LiveEventsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/live-events/:id`}
            component={asyncComponent(() =>
              import("./LiveEventsPage/ViewLiveEventsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/live-events/:id/edit`}
            component={asyncComponent(() =>
              import("./LiveEventsPage/EditLiveEventsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/beta-live-events`}
            component={asyncComponent(() => import("./BetaLiveEventsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/beta-live-events/:id`}
            component={asyncComponent(() =>
              import("./BetaLiveEventsPage/ViewBetaLiveEventsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/beta-live-events/:id/edit`}
            component={asyncComponent(() =>
              import("./BetaLiveEventsPage/EditBetaLiveEventsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/paid-events`}
            component={asyncComponent(() => import("./PaidEventsPage"))}
          />
          <Route
            exact
            path={`${match.url}:app/paid-events/create`}
            component={asyncComponent(() =>
              import("./PaidEventsPage/CreatePaidEventsPage")
            )}
          />
          <Route
            exact
            path={`${match.url}:app/paid-events/:id`}
            component={asyncComponent(() =>
              import("./PaidEventsPage/ViewPaidEventsPage")
            )}
          />
          <Route
            exact
            path={`${match.url}:app/paid-events/:id/edit`}
            component={asyncComponent(() =>
              import("./PaidEventsPage/EditPaidEventsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/merch`}
            component={asyncComponent(() => import("./MerchPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/stamps`}
            component={asyncComponent(() => import("./StampsPage"))}
          />
          <Route
            exact
            path={`${match.url}:app/stamps/create`}
            component={asyncComponent(() => import("./StampsPage/CreateStampPage"))}
          />
          <Route
            exact
            path={`${match.url}:app/stamps/:id`}
            component={asyncComponent(() => import("./StampsPage/ViewStampsPage"))}
          />
          <Route
            exact
            path={`${match.url}:app/stamps/:id/edit`}
            component={asyncComponent(() => import("./StampsPage/EditStampsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/albums`}
            component={asyncComponent(() => import("./AlbumsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/audiofiles`}
            component={asyncComponent(() => import("./AudioFilesPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/audiofiles/create`}
            component={asyncComponent(() => import("./AudioFilesPage/CreateAudioFilePage"))}
          />

          <Route
            exact
            path={`${match.url}:app/concerts`}
            component={asyncComponent(() => import("./ConcertsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/concerts/create`}
            component={asyncComponent(() =>
              import("./ConcertsPage/CreateConcertPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/concerts/:id`}
            component={asyncComponent(() =>
              import("./ConcertsPage/ViewConcertsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/concerts/:id/edit`}
            component={asyncComponent(() =>
              import("./ConcertsPage/EditConcertsPage")
            )}
          />

          <Route
            exact
            path={`${match.url}:app/feeds`}
            component={asyncComponent(() => import("./FeedsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/feeds/create`}
            component={asyncComponent(() => import("./FeedsPage/CreateFeedPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/feeds/:id`}
            component={asyncComponent(() => import("./FeedsPage/ViewFeedsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/feeds/:id/edit`}
            component={asyncComponent(() => import("./FeedsPage/EditFeedsPage"))}
          />

          <Route
            exact
            path={`${match.url}:app/logs`}
            component={asyncComponent(() => import("./LogsPage"))}
          />


        </Switch>
    </div>
  );
};

export default App;
