import { deleteAll, deleteById, deleteImageFromFirebaseStorage } from "../firebase/apiFirebase";
import { message, Modal } from "antd";
import React from "react";

const showDeleteModal = (text, callback) => {
  Modal.confirm({
    icon: <img className="modal-icon" src="/assets/images/danger.png" alt="danger"/>,
    okText: <span className="modal-delete-button">DELETE</span>,
    cancelText: <span className="modal-cancel-button ">Cancel</span>,
    title: <span className="modal-title">{text}</span>,
    onOk: callback
  });
}

export const deleteProfile = (firebaseConfig, resource, id, path, history) => {
    showDeleteModal(
      "Are you sure you want to delete this entry?",
      () => {
      deleteById(firebaseConfig, resource, id).then(() => {
        const actualPath = window.location.href;
        actualPath.substr(window.location.origin.length);
        const pathArray = actualPath.substr(window.location.origin.length).split('/');
        const url = '/' + pathArray[1] +'/'+ pathArray[2];
        if (path || (Array.isArray(path) && path.length)) {
          deleteImageFromFirebaseStorage(firebaseConfig, resource, path).then();
        }
        history.push(url);
      });
    }
  )
}

export const deleteProfileOnTable = (firebaseConfig, resource, id, method, setMethod, path) => {
  return new Promise((resolve, reject) => {
    try {
      showDeleteModal(
        "Are you sure you want to delete this entry?", () => {
          deleteById(firebaseConfig, resource, id).then(() => {
            const deletionIndex = method.findIndex((index) => (index.id || index.uid) === id);
            const dataCopy = method.slice();
            dataCopy.splice(deletionIndex, 1);
            setMethod(dataCopy);
            if (path || (Array.isArray(path) && path.length)) {
              deleteImageFromFirebaseStorage(firebaseConfig, resource, path).then();
            }
            resolve();
          });
        }
      )
    } catch (e) {
      reject(e);
    }
  })
};

export const deleteSelectedEntries = (firebaseConfig, resource, data, setData, selectedRows, setSelectedRows) => {
    return new Promise((resolve, reject) => {
        try {
          showDeleteModal(
        "Are you sure you want to delete this entries?", () => {
              deleteAll(firebaseConfig, resource, selectedRows).then(() => {
                  let dataIndex = 0;
                  const dataCopy = data.slice();
                  const selectedCopy = selectedRows.slice();
                  while (selectedCopy.length && dataIndex < dataCopy.length) {
                      // eslint-disable-next-line
                      const foundIndex = selectedCopy.findIndex((item) => item.uid === dataCopy[dataIndex].uid);

                      if (foundIndex !== -1) {
                        const paths = [];

                        ['photo', 'stamp', 'image'].forEach((item) => {
                          if (selectedCopy[foundIndex][item]) paths.push(selectedCopy[foundIndex][item])
                        });

                        if (paths.length) {
                          deleteImageFromFirebaseStorage(firebaseConfig, resource, paths).then();
                        }

                        dataCopy.splice(dataIndex, 1);
                        selectedCopy.splice(foundIndex, 1);
                        continue;
                      }

                      dataIndex++;
                  }
                  setData(dataCopy);
                  setSelectedRows(selectedCopy);

                  return message.success(`The selected items were deleted`);
              }).then(r => {
                  resolve();
              });
                }
            )
        } catch (e) {
            reject(e);
        }
    })
};
