import React, { useEffect } from "react";
import { Layout, Popover } from "antd";
import { useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import {
  toggleCollapsedSideNav,
} from "../../appRedux/actions";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { projectData, projectBands } from "./projectData";
import { getAppName } from "../../apiFunctions/blackboxApi";
import { getConfigByPath } from "../../firebase/utilsFirebase";
import { getAll, imageUrl } from "../../firebase/apiFirebase";
import UserProfile from "../Sidebar/UserProfile";

const { Header } = Layout;

const Topbar = ({ photo, setPhoto, logos, setLogos }) => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const { userRole } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const config = getConfigByPath();

  const getPathName = (project) => {
    const app = getAppName(project);
    const actualPath = window.location.href;
    actualPath.substr(window.location.origin.length);
    const pathArray = actualPath.substr(window.location.origin.length).split('/');
    pathArray[1] = app;
    const finalUrl = "/" + pathArray[1] + "/" + pathArray[2];
    history.push(finalUrl);
  }

  useEffect(() => {
    setPhoto("");
    getAll(config, "logos", setLogos).then()
    // eslint-disable-next-line
  }, [config.name]);

  useEffect(() => {
    if (config && config.name !== "General") {
      if (logos && logos[0] && logos[0].logo) {
        imageUrl(config, logos[0].logo).then((r) => {
          setPhoto(r)
        });
      } else {
        setPhoto("");
      }
    } else {
      setPhoto("");
    }
    // eslint-disable-next-line
  }, [logos]);

  const projectMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {userRole === "admin" && projectData.map((project) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(project)}
            onClick={() => {
              getPathName(project);
              localStorage.setItem("usrcf", JSON.stringify(project));
            }}
          >
            {project.name === "BB Justin Nozuka" ? <span className="gx-language-text">JN App</span> : <span className="gx-language-text">{project.name}</span>}
          </li>
        ))}

        {userRole === "bands" && projectBands.map((project) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(project)}
            onClick={() => {
              getPathName(project);
              localStorage.setItem("usrcf", JSON.stringify(project));
            }}
          >
            {project.name === "BB Justin Nozuka" ? <span className="gx-language-text">JN App</span> : <span className="gx-language-text">{project.name}</span>}
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      {photo && (
        <div className="logoWrapper">
          <img className="logo" src={photo || ""} alt="tgs" />
        </div>
        )
      }

      <ul className="gx-header-notifications gx-ml-auto">
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </Auxiliary>
        )}

        <div className="user-profile">
          <UserProfile />
        </div>

        {userRole !== "" && (userRole === "admin" || userRole === "bands") && (
          <div>
            <li className="gx-language">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={projectMenu()}
                trigger="click"
              >
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  {config.name === "BB Justin Nozuka" ? <span>JN App</span> : <span>{config.name}</span>}
                  <i className="icon icon-chevron-down gx-pl-2" />
                </span>
              </Popover>
            </li>
          </div>
        )}

        <div className="version">Ver. 1.3</div>

        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default Topbar;
