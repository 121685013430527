import { Form, Input } from "antd";
import React, { useState, useEffect } from "react";
import { searchInObject } from "../../util/searchfunctions";

const SearchByTerm = (props) => {
  const { field, setFilteredData, data } = props;
  const [searchField, setSearchField] = useState("");

  useEffect(()=>{
    //call function when something change in state
    handleFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchField]) //dependency added

  const handleFilterData = () => {
    if (searchField !== "") {
      let fields = field;

      if (!Array.isArray(fields)) {
        fields = [fields]
      }

      const c = searchInObject(data, searchField, fields);
      setFilteredData(c);
    } else {
      setFilteredData(data);
    }
  };

  return (
    <div>
      <Form layout="inline">
        <Form.Item label="Search">
          <Input
            placeholder="Search by term"
            value={searchField}
            allowClear={true}
            onChange={(e) => setSearchField(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SearchByTerm;
