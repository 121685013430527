import { SET_PROJECT_CONFIG, REMOVE_PROJECT_CONFIG } from "constants/ActionTypes";

const localConfig = localStorage.getItem("usrcf") || "";
const localConfigParsed = localConfig ? JSON.parse(localConfig) : "";

const INIT_STATE = {
  config: localConfigParsed,
};

const configReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PROJECT_CONFIG: {
      return {
        ...state,
        config: action.payload,
      };
    }
    case REMOVE_PROJECT_CONFIG: {
      return {
        ...state,
        config: "",
      };
    }
    default:
      return state;
  }
};

export default configReducer;
