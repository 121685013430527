import Resizer from "react-image-file-resizer";

// helper to get dimensions of an image
export const imageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // handler will fire after the successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // handler will fire if there was an error with the image (if it's not an image or a corrupted one)
    img.onerror = () => {
      reject("There was some problem with the image.");
    };

    img.src = URL.createObjectURL(file);
  });

// how to use the helper
export const getImageInfo = async (file) => {
  try {
    const dimensions = await imageDimensions(file);
    return dimensions;
  } catch (error) {
    console.error(error);
  }
};

export const resizeFile = async (file, type) => {
  const dimensions = await getImageInfo(file);
  if (dimensions.width > 1024 || dimensions.height > 1024) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        type,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  }
  else {
    return file;
  }
};
