import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { getAppName } from "../../apiFunctions/blackboxApi";
import { getConfigByPath } from "../../firebase/utilsFirebase";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  const { SubMenu } = Menu;

  const config = getConfigByPath();

  const isGeneral = config && config.name === "General";

  const app = getAppName(config);

  const selectedKeys = pathname.substr(1).split('/').slice(1).join('/');
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content content-side">
        <CustomScrollbars className="gx-layout-sider-scrollbar content-scroll">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="overview">
              <Link to={`/${app}/overview`}>
                <img
                  src="/assets/images/overview.png"
                  alt="overview"
                  className="icon icon-default"
                />
                <span>
                  <IntlMessages id="sidebar.overview" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="notifications">
              <Link to={`/${app}/notifications`}>
                <img
                  src="/assets/images/notifications.png"
                  alt="notification"
                  className="icon icon-default"
                />
                <span>
                  <IntlMessages id="sidebar.notifications" />
                </span>
              </Link>
            </Menu.Item>
            <SubMenu
              className="submenu"
              key="profile-management"
              title="Profile Management"
              icon={<img src="/assets/images/profiles.png" className="icon icon-default " alt="icon"/>}>
              <Menu.Item key="profiles">
                <Link to={`/${app}/profiles`}>
                  <img
                    src="/assets/images/profiles.png"
                    alt="user"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.profilePage" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="concerts">
                <Link to={`/${app}/concerts`}>
                  <img
                    src="/assets/images/concerts.png"
                    alt="maloik"
                    className="icon icon-default"
                  />
                  <span>
                  <IntlMessages id="sidebar.concerts" />
                </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="albums">
                <Link to={`/${app}/albums`}>
                  <img
                    src="/assets/images/albums.png"
                    alt="music album"
                    className="icon icon-default"
                  />
                  <span>
                  <IntlMessages id="sidebar.albums" />
                </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              className="submenu"
              key="events"
              title="Events"
              icon={<img src="/assets/images/events.png" className="icon icon-default " alt="icon"/>}>
              <Menu.Item key="live-events">
                <Link to={`/${app}/live-events`}>
                  <img
                    src="/assets/images/events.png"
                    alt="streaming"
                    className="icon icon-default "
                  />
                  <span>
                    <IntlMessages id="sidebar.liveEvents" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="beta-live-events">
                <Link to={`/${app}/beta-live-events`}>
                  <img
                    src="/assets/images/events.png"
                    alt="streaming"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.betaLiveEvents" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="paid-events">
                <Link to={`/${app}/paid-events`}>
                  <img
                    src="/assets/images/events.png"
                    alt="streaming"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.paidEvents" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              className="submenu"
              key="rewards"
              title="Rewards"
              icon={<img src="/assets/images/merch_stamps.png" className="icon icon-default " alt="icon"/>}>
              <Menu.Item key="merch">
                <Link to={`/${app}/merch`}>
                  <img
                    src="/assets/images/merch_stamps.png"
                    alt="merchandising"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.merch" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="stamps">
                <Link to={`/${app}/stamps`}>
                  <img
                    src="/assets/images/concert_stamps.png"
                    alt="seal"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.stamps" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              className="submenu"
              key="media"
              title="Media"
              icon={<img src="/assets/images/media.png" className="icon icon-default " alt="icon"/>}>
              <Menu.Item key="upload-video">
                <Link to={`/${app}/upload-video`}>
                  <img
                    src="/assets/images/upload_video.png"
                    alt="upload"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.uploadVideo" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="feeds">
                <Link to={`/${app}/feeds`}>
                  <img
                    src="/assets/images/media.png"
                    alt="rss"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.media" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="audioFiles">
                <Link to={`/${app}/audioFiles`}>
                  <img
                    src="/assets/images/media.png"
                    alt="rss"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.audio" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            {!isGeneral &&
              <Menu.Item key="configs">
                <Link to={`/${app}/configs`}>
                  <img
                    src="/assets/images/themes.png"
                    alt="configs"
                    className="icon icon-default"
                  />
                  <span>
                    <IntlMessages id="sidebar.themes" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {isGeneral &&
                <Menu.Item key="admin">
                  <Link to={`/${app}/admin`}>
                    <img
                        src="/assets/images/profiles.png"
                        alt="admin"
                        className="icon icon-default"
                    />
                    <span>
                  <IntlMessages id="sidebar.admin" />
                </span>
                  </Link>
                </Menu.Item>
            }
            <Menu.Item key="subscriptions">
              <Link to={`/${app}/subscriptions`}>
                <img
                  src="/assets/images/subscriptions.png"
                  alt="subscription"
                  className="icon icon-default"
                />
                <span>
                  <IntlMessages id="sidebar.subscriptions" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
