import axios from "axios";
import { auth } from "../firebase/firebase";

const baseURL = process.env.REACT_APP_BLACKBOX_API;

export const getAppName = (config) => {
  switch (config.name) {
    case "Alan Frew":
      return "afrew";
    case "BB Justin Nozuka":
      return "justin";
    case "TGS Union":
      return "tgs";
    case "BB Beta":
      return "beta";
    case "JJ Wilde":
      return "jjwilde";
    case "Brian Kelley":
      return "kelley";
    case "Wheeler Walker":
      return "wheeler";
    case "General":
      return "general";
    default:
      return "";
  }
};

export const getCustomToken = async (config) => {
  const app = getAppName(config);

  const user = await new Promise((resolve, reject) =>
    auth.onAuthStateChanged(
      (user) => resolve(user),
      (e) => reject(e)
    )
  );

  if (user) {
    const token = await user.getIdToken();
    if (token) {
      const config = {
        method: "post",
        url: `${baseURL}/authenticate/${app}`,
        // url: `http://localhost:5000/api/authenticate/${app}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { token: token },
      };

      return axios(config)
        .then((res) => res.data)
        .catch((err) => err);
    }
  }
};

export const getUsers = async (config, setData) => {
  const app = getAppName(config);

  const user = await new Promise((resolve, reject) =>
    auth.onAuthStateChanged(
      (user) => resolve(user),
      (e) => reject(e)
    )
  );

  const token = await user.getIdToken();

  if (token) {
    const config = {
      method: "post",
      url: `${baseURL}/users`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token: token, role: app },
    };

    const response = await axios(config)
      .then((res) => setData(res.data.allUsers))
      .catch((err) => err);
  }
};

export const createSubscription = (
  config,
  card,
  expiration,
  values,
  name,
  period,
  trialOccurrences,
  trialAmount,
  currentPlanId
) => {
  const app = getAppName(config);
  const subscription = {
    method: "post",
    url: `${baseURL}/createSubscription/${app}`,
    // url: `http://localhost:5000/api/createSubscription/${app}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      cardNumber: card,
      expirationDate: expiration,
      billingInformation: values,
      name,
      period,
      trialOccurrences,
      trialAmount,
      currentPlanId,
    },
  };

  return axios(subscription)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getSubscription = (config, name) => {
  const app = getAppName(config);
  const subscription = {
    method: "post",
    url: `${baseURL}/getSubscription/${app}`,
    // url: `http://localhost:5000/api/getSubscription/${app}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { name: name },
  };

  return axios(subscription);
};

export const getSubscriptionPlans = (config) => {
  const app = getAppName(config);
  const subscription = {
    method: "post",
    url: `${baseURL}/getSubscriptionPlans/${app}`,
    // url: `http://localhost:5000/api/getSubscriptionPlans/${app}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(subscription);
};
