import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { Button } from "antd";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { getButtonsColor } from "../../util/getButtonsColor";
import { getConfigByPath } from "../../firebase/utilsFirebase";
import { LoadingOutlined } from "@ant-design/icons";
import SearchByTerm from "./SearchByTerm";

const CustomTable = (props) => {
  const {
    data,
    columns,
    hasSearchbar,
    hasExportButton,
    rowKey,
    createLink,
    field,
    resource = "data",
    loading,
    setSelectedRows,
    selectedRows,
    deleteAll,
  } = props;

  const config = getConfigByPath();

  const [filteredData, setFilteredData] = useState([]);

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={antIcon} className={`loading-indicator ${getButtonsColor(config)}`}/>,
  }

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const createCSV = () => {
    const cols = columns.filter((c) => c.key !== "actions").map((c) => c.key);
    let dump = [];

    const intArrays = filteredData.filter((v) =>
      selectedRows.some((s) => s.name === v.name)
    );

    intArrays.forEach((fd) => {
      let obj = {};
      cols.forEach((c) => {
        obj[c] = fd[c];
      });
      dump.push(obj);
    });
    return dump;
  };

  const renderSearchSection = () => {
    if (hasSearchbar) {
      return (
        <SearchByTerm
          data={data}
          setFilteredData={setFilteredData}
          field={field}/>
        )
    }
    return <></>;
  };

  return (
    <div>
      <div
        className={`gx-d-flex ${
          hasSearchbar ? "gx-justify-content-between" : "gx-justify-content-end"
        }`}
      >
        {renderSearchSection()}
        <div className="gx-d-flex">
          {createLink && (
            <div>
              <Link to={createLink} className="gx-mr-3">
                <Button>Create</Button>
              </Link>
            </div>
          )}
          {hasExportButton && (
            <div>
              <Button>
                <CSVLink
                  data={createCSV()}
                  filename={`${resource}-export-${new Date().getTime()}`}
                >
                  Export
                </CSVLink>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div>
        <Button onClick={() => deleteAll()} disabled={!selectedRows.length}> Delete All</Button>
      </div>

      {hasExportButton ? (
        <Table
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          rowKey={rowKey}
          locale={{emptyText: loading ? "" : <div>No data</div>}}
          loading={tableLoading}
          scroll={{ x: 1000 }}
          pagination={{ pageSizeOptions: ['10', '20', '50', '100', '1000']}}
        />
      ) : (
        <Table
            locale={{emptyText: loading ? "" : <div>No data</div>}}
            dataSource={filteredData}
            columns={columns}
            rowKey={rowKey}
            loading={tableLoading}
            scroll={{ x: 1000 }}
            pagination={{ pageSizeOptions: ['10', '20', '50', '100', '1000']}}
        />
      )}
    </div>
  );
};

export default CustomTable;
