import { SET_PROJECT_STYLES } from "constants/ActionTypes";

const INIT_STATE = {
    logo: "",
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: "",
};

const logoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PROJECT_STYLES: {
      return {
        ...state,
        logo: action.payload.logo,
        color1: action.payload.color1,
        color2: action.payload.color2,
        color3: action.payload.color3,
        color4: action.payload.color4,
        color5: action.payload.color5,
      };
    }
    default:
      return state;
  }
};

export default logoReducer;
